exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-components-about-js": () => import("./../../../src/pages/Components/About.js" /* webpackChunkName: "component---src-pages-components-about-js" */),
  "component---src-pages-components-archive-year-js": () => import("./../../../src/pages/Components/ArchiveYear.js" /* webpackChunkName: "component---src-pages-components-archive-year-js" */),
  "component---src-pages-components-article-preview-js": () => import("./../../../src/pages/Components/ArticlePreview.js" /* webpackChunkName: "component---src-pages-components-article-preview-js" */),
  "component---src-pages-components-article-reading-js": () => import("./../../../src/pages/Components/ArticleReading.js" /* webpackChunkName: "component---src-pages-components-article-reading-js" */),
  "component---src-pages-components-back-issues-js": () => import("./../../../src/pages/Components/BackIssues.js" /* webpackChunkName: "component---src-pages-components-back-issues-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/Components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-nav-menu-js": () => import("./../../../src/pages/Components/NavMenu.js" /* webpackChunkName: "component---src-pages-components-nav-menu-js" */),
  "component---src-pages-components-no-match-js": () => import("./../../../src/pages/Components/NoMatch.js" /* webpackChunkName: "component---src-pages-components-no-match-js" */),
  "component---src-pages-components-okay-hero-js": () => import("./../../../src/pages/Components/OkayHero.js" /* webpackChunkName: "component---src-pages-components-okay-hero-js" */),
  "component---src-pages-components-supporters-js": () => import("./../../../src/pages/Components/Supporters.js" /* webpackChunkName: "component---src-pages-components-supporters-js" */),
  "component---src-pages-components-title-js": () => import("./../../../src/pages/Components/Title.js" /* webpackChunkName: "component---src-pages-components-title-js" */),
  "component---src-pages-containers-archive-container-js": () => import("./../../../src/pages/Containers/ArchiveContainer.js" /* webpackChunkName: "component---src-pages-containers-archive-container-js" */),
  "component---src-pages-containers-article-container-js": () => import("./../../../src/pages/Containers/ArticleContainer.js" /* webpackChunkName: "component---src-pages-containers-article-container-js" */),
  "component---src-pages-containers-main-js": () => import("./../../../src/pages/Containers/Main.js" /* webpackChunkName: "component---src-pages-containers-main-js" */),
  "component---src-pages-containers-side-bar-js": () => import("./../../../src/pages/Containers/SideBar.js" /* webpackChunkName: "component---src-pages-containers-side-bar-js" */),
  "component---src-pages-idx-js": () => import("./../../../src/pages/idx.js" /* webpackChunkName: "component---src-pages-idx-js" */),
  "component---src-pages-okayhero-js": () => import("./../../../src/pages/okayhero.js" /* webpackChunkName: "component---src-pages-okayhero-js" */),
  "component---src-pages-supporters-js": () => import("./../../../src/pages/supporters.js" /* webpackChunkName: "component---src-pages-supporters-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-issue-page-js": () => import("./../../../src/templates/issue-page.js" /* webpackChunkName: "component---src-templates-issue-page-js" */)
}

